<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader v-if="mitarbeiter">
          <CIcon name="cil-calendar"/>
          Eine neue Abwesenheit / Krankheit anlegen für {{mitarbeiter.name}}
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="newAbwesenheit()">

              <AbwesenheitText v-model="name" formLabel="Name"/>
              <CSelect
                label="Art"
                :value.sync="art"
                :isValid="true"
                :options="statusArt"
                class="mt-2"
              />
              <AbwesenheitDatum v-model="datum" formLabel="Datum der Abwesenheit / Krankheit" class="mt-3"/>
              <AbwesenheitNumber v-model="dauer" formLabel="Dauer der Abwesenheit / Krankheit"/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Abwesenheit / Krankheit anlegen</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import AbwesenheitText from '@/components/FormElements/Text'
import AbwesenheitNumber from '@/components/FormElements/Number'
import AbwesenheitDatum from '@/components/FormElements/Date'
import moment from 'moment'

export default {
  name: 'MitarbeiterEdit',
  components: {
    FormError,
    AbwesenheitText,
    AbwesenheitNumber,
    AbwesenheitDatum
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      name: '',
      art: 'Urlaub',
      datum: '',
      dauer: 8.00,
      mitarbeiter: null,
      formError: null,
      statusArt: [
        { value: 'Urlaub', label: 'Urlaub' },
        { value: 'Krankheit', label: 'Krankheit' },
        { value: 'Anderes', label: 'Anderes' }
      ]
    }
  },
  mounted () {
    this.loadAbwesenheit()
  },
  methods: {
    loadAbwesenheit () {
      Vue.axios.get('/abwesenheit/details/' + this.uuid)
        .then((response) => {
          this.name = response.data.name
          this.art = response.data.art
          this.datum = this.dateFormatter(response.data.datum)
          this.dauer = this.floatFormatter(response.data.dauer)
          this.loadMitarbeiter(response.data.mitarbeiter.mitarbeiterid)
        })
    },
    loadMitarbeiter (mitarbeiterid) {
      Vue.axios.get('/mitarbeiter/get/user/' + mitarbeiterid)
        .then((response) => {
          response.data = response.data[0]
          this.mitarbeiter = response.data
        })
    },
    floatFormatter (value) {
      if (typeof value === 'string') {
        return parseFloat(value)
      } else {
        return value
      }
    },
    dateFormatter (value) {
      if (value) {
        value = moment(value).format('DD.MM.YYYY')
      } else {
        value = null
      }
      return value
    },
    newAbwesenheit () {
      Vue.axios.put('/abwesenheit/put/' + this.uuid, {
        mitarbeiter: this.mitarbeiter.mitarbeiterid,
        name: this.name,
        art: this.art,
        datum: this.datum,
        dauer: this.dauer
      }).then((response) => {
        this.$router.push({ path: '/abwesenheiten/uebersicht/' + this.mitarbeiter.mitarbeiterid })
        this.$snotify.success('Die Abwesenheit / Krankheit wurde geändert.', {
          position: 'rightTop',
          timeout: 4000
        })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
